import { Capacitor } from "@capacitor/core";

export function sendPlausibleEvent(eventName: string) {
  if (window.plausible) {
    window.plausible(eventName, {
      props: {
        platform: Capacitor.getPlatform()
      }
    });
  } else {
    console.warn("Plausible not found");
  }
}
